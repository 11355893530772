import { IMenu } from './useMenus'

export const findParentChildPath = (menus: IMenu[], key: string): IMenu[] | null => {
  // 定义递归函数
  const findFn = (menu: IMenu, path: IMenu[]): IMenu[] | null => {
    // 将当前节点加入路径
    path.push(menu)

    // 如果找到目标节点，返回完整路径
    if (menu.key === key) return path

    // 如果当前节点有子节点，则递归查找子节点
    if (menu.children) {
      for (const child of menu.children) {
        const result = findFn(child, path)
        if (result) return result
      }
    }

    // 如果未找到目标节点，移除当前节点
    path.pop()
    return null
  }

  // 遍历树的每个根节点，查找路径
  for (const rootNode of menus) {
    const path = findFn(rootNode, [])
    if (path) return path
  }

  // 如果在整个树中都未找到目标节点，返回 null
  return null
}
