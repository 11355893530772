import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import Icon, { AppstoreOutlined, SettingOutlined, ShopOutlined, TruckOutlined } from '@ant-design/icons'

import { Texty } from '@/components/Texty'
import { useGlobalState } from '@/globalStore/globalStore'
import DeliveryIcon from '@/assets/icons/delivery.svg?react'
import DingdanIcon from '@/assets/icons/dingdan.svg?react'
import PkgMaterialIcon from '@/assets/icons/pkgMaterial.svg?react'
import SupplierIcon from '@/assets/icons/supplier.svg?react'
import TransactionIcon from '@/assets/icons/transaction.svg?react'
import WarehouseIcon from '@/assets/icons/warehouse.svg?react'
import YundanIcon from '@/assets/icons/yundan.svg?react'
import ZhuizongIcon from '@/assets/icons/zhuizong.svg?react'

export interface IMenu<TLabelType extends string | ReactNode = string> {
  label: TLabelType
  /**
   * 1. 可折叠项用 $ 前缀，值随意，唯一即可
   * 2. 可导航项用 / 前缀，值为完整 pathname + search + hash
   */
  key: string
  auth?: [string, ...string[]]
  /**
   * 配置动态 pathname，用于菜单自动选中
   */
  pathPattern?: string
  icon?: ReactNode
  /**
   * 只能出现在第一层
   */
  children?: IMenu<TLabelType>[]
}

export const useMenus = (
  collapsed: boolean,
): {
  menus: IMenu[]
  items: Omit<IMenu<ReactNode>, 'auth' | 'pathPattern'>[]
} => {
  const { t } = useTranslation()
  const codeMap = useGlobalState(s => s.userInfo!.roleCodeMap)

  const menus = useMemo(() => {
    const result: IMenu[] = [
      // ====== 商家侧 ======
      {
        label: t('9-nav.ding-dan-guan-li'),
        key: '$order',
        auth: ['102'],
        icon: <Icon component={DingdanIcon} />,
        children: [
          {
            key: '/biz/order/list',
            label: t('9-nav.ding-dan-lie-biao'),
          },
          {
            key: '/biz/order/error',
            label: t('9-nav.yi-chang-ding-dan'),
          },
        ],
      },
      {
        label: t('9-nav.yun-dan'),
        key: '/biz/order/inFulfillmen',
        auth: ['102'],
        icon: <Icon component={YundanIcon} />,
      },
      {
        label: t('9-nav.yun-dan-zhui-zong'),
        key: '/biz/order/track',
        auth: ['102'],
        icon: <Icon component={ZhuizongIcon} />,
      },
      {
        label: t('9-nav.dian-pu-guan-li'),
        key: '$shop',
        auth: ['102'],
        icon: <ShopOutlined />,
        children: [
          {
            key: '/biz/shop/list',
            label: t('9-nav.dian-pu-lie-biao'),
          },
          {
            key: '/biz/shop/productMatch',
            label: t('9-nav.shang-pin-pi-pei'),
          },
          {
            key: '/biz/shop/localProductList',
            label: t('9-nav.ben-di-chan-pin'),
          },
        ],
      },
      {
        label: t('9-nav.wu-liu-guan-li'),
        key: '/biz/logistics/list',
        auth: ['102'],
        icon: <TruckOutlined />,
      },
      {
        label: t('9-nav.cang-ku-guan-li'),
        key: '$warehouse',
        auth: ['102'],
        icon: <Icon component={WarehouseIcon} />,
        children: [
          {
            key: '/biz/warehouse/stock',
            label: t('9-nav.ku-cun-lie-biao'),
          },
          {
            key: '/biz/warehouse/outOfStock',
            label: t('9-nav.que-huo-ku-cun-lie-biao'),
          },
          {
            key: '/biz/warehouse/stockFlow',
            label: t('9-nav.ku-cun-liu-shui'),
          },
        ],
      },
      {
        label: t('9-nav.ti-huo-guan-li'),
        key: '/biz/delivery',
        auth: ['102'],
        icon: <Icon component={DeliveryIcon} />,
      },
      {
        label: t('9-nav.gong-ying-shang-guan-li'),
        key: '/biz/supplier',
        auth: ['102'],
        icon: <Icon component={SupplierIcon} />,
      },
      {
        label: t('9-nav.bao-cai-guan-li'),
        key: '/biz/pkgMaterial',
        auth: ['102'],
        icon: <Icon component={PkgMaterialIcon} />,
      },
      {
        label: t('9-nav.jiao-yi-guan-li'),
        key: '$transaction',
        auth: ['102'],
        icon: <Icon component={TransactionIcon} />,
        children: [
          {
            key: '/biz/transaction/recharge',
            label: t('9-nav.zhang-hu-chong-zhi'),
          },
          {
            key: '/biz/transaction/accountFlow',
            label: t('9-nav.zi-jin-liu-shui'),
          },
          {
            key: '/biz/transaction/bill',
            label: t('9-nav.wo-de-zhang-dan'),
          },
        ],
      },
      {
        label: t('9-nav.she-zhi'),
        key: '$setting',
        auth: ['102'],
        icon: <SettingOutlined />,
        children: [
          {
            key: '/biz/setting/billing',
            label: 'Billing',
          },
        ],
      },
      // ====== 员工侧 ======
      {
        label: t('9-nav.lv-yue-dan-guan-li'),
        key: '$admin/order',
        auth: ['101'],
        icon: <Icon component={DingdanIcon} />,
        children: [
          {
            key: '/biz/admin/order/audit',
            label: t('9-nav.lv-yue-dan-shen-he'),
          },
          {
            key: '/biz/admin/order/error',
            label: t('9-nav.yi-chang-lv-yue-dan'),
          },
        ],
      },
      {
        key: '/biz/admin/order/track',
        label: t('9-nav.yun-dan-zhui-zong'),
        auth: ['101'],
        icon: <Icon component={ZhuizongIcon} />,
      },
      {
        label: t('9-nav.dian-pu-guan-li'),
        key: '$admin/shop',
        auth: ['101'],
        icon: <ShopOutlined />,
        children: [
          {
            key: '/biz/admin/shop/localProductList',
            label: t('9-nav.ben-di-chan-pin'),
          },
        ],
      },
      {
        label: t('9-nav.wu-liu-guan-li'),
        key: '$admin/logistics',
        auth: ['101'],
        icon: <TruckOutlined />,
        children: [
          {
            label: t('9-nav.wu-liu-lie-biao'),
            key: '/biz/admin/logistics/list',
          },
          {
            label: t('9-nav.yun-fei-mo-ban'),
            key: '/biz/admin/logistics/freightTemplates',
          },
        ],
      },
      {
        label: t('9-nav.cang-ku-guan-li'),
        key: '$admin/warehouse',
        auth: ['101'],
        icon: <Icon component={WarehouseIcon} />,
        children: [
          {
            key: '/biz/admin/warehouse/stock',
            label: t('9-nav.ku-cun-lie-biao'),
          },
          {
            key: '/biz/admin/warehouse/outOfStock',
            label: t('9-nav.que-huo-ku-cun-lie-biao'),
          },
          {
            key: '/biz/admin/warehouse/stockFlow',
            label: t('9-nav.ku-cun-liu-shui'),
          },
        ],
      },
      {
        label: t('9-nav.ti-huo-guan-li'),
        key: '/biz/admin/delivery',
        auth: ['101'],
        icon: <Icon component={DeliveryIcon} />,
      },
      {
        label: t('9-nav.bao-cai-guan-li'),
        key: '/biz/admin/pkgMaterial',
        auth: ['101'],
        icon: <Icon component={PkgMaterialIcon} />,
      },
      {
        label: t('9-nav.jiao-yi-guan-li'),
        key: '$admin/transaction',
        auth: ['101'],
        icon: <Icon component={TransactionIcon} />,
        children: [
          {
            key: '/biz/admin/transaction/recharge',
            label: t('9-nav.zhang-hu-chong-zhi'),
          },
          {
            key: '/biz/admin/transaction/accountFlow',
            label: t('9-nav.zi-jin-liu-shui'),
          },
          {
            key: '/biz/admin/transaction/bill',
            label: t('9-nav.zhang-dan-lie-biao'),
          },
          {
            key: '/biz/admin/transaction/bankStatement',
            label: t('9-nav.yin-hang-liu-shui'),
          },
        ],
      },
      {
        label: t('9-nav.xi-tong-guan-li'),
        key: '$admin/whiteList',
        auth: ['100', '101'],
        icon: <AppstoreOutlined />,
        children: [
          {
            key: '/biz/admin/whiteList',
            label: t('9-nav.bai-ming-dan-guan-li'),
          },
        ],
      },
      // ====== 财务侧 ======
      {
        label: t('9-nav.jiao-yi-guan-li'),
        key: '$finance/transaction',
        auth: ['103'],
        icon: <Icon component={TransactionIcon} />,
        children: [
          {
            key: '/biz/finance/transaction/recharge',
            label: t('9-nav.chong-zhi-shen-he'),
          },
          {
            key: '/biz/finance/transaction/bankStatement',
            label: t('9-nav.yin-hang-liu-shui'),
          },
        ],
      },
      // ====== 仓库侧 ======
      {
        label: t('9-nav.dian-pu-guan-li'),
        key: '$store/shop',
        auth: ['104'],
        icon: <ShopOutlined />,
        children: [
          {
            key: '/biz/store/shop/localProductList',
            label: t('9-nav.ben-di-chan-pin'),
          },
        ],
      },
      {
        label: t('9-nav.bao-cai-guan-li'),
        key: '/biz/store/pkgMaterial',
        auth: ['104'],
        icon: <Icon component={PkgMaterialIcon} />,
      },
    ]
    return result
      .filter(({ auth }) => (auth ? auth.some(code => codeMap[code]) : true)) // 第一层过滤
      .map(({ ...menu }) => {
        if (menu.children) {
          menu.children = menu.children.filter(
            ({ auth }) => (auth ? auth.some(code => codeMap[code]) : true), // 第二层过滤
          )
          menu.children = menu.children.map(({ ...item }) => {
            delete item.children // 删除第三层
            return item
          })
        }
        return menu
      })
  }, [codeMap, t])

  const items = useMemo<Omit<IMenu<ReactNode>, 'auth' | 'pathPattern'>[]>(() => {
    const addTexty = (item: IMenu<ReactNode>): IMenu<ReactNode> => {
      if (collapsed) return item
      return {
        ...item,
        label: (
          <Texty usageTooltipComponent placement="right" mouseEnterDelay={0.5} mouseLeaveDelay={0} hideOnScroll={false}>
            {item.label}
          </Texty>
        ),
      }
    }
    return menus.map(menu => {
      const item: IMenu<ReactNode> = { ...menu }
      const omitKeys = ['auth', 'pathPattern'] as const
      if (item.children) {
        item.children = item.children.map(d => addTexty(_.omit(d, omitKeys)))
      }
      return addTexty(_.omit(item, omitKeys))
    })
  }, [collapsed, menus])

  return { menus, items }
}
