import { Navigate, Outlet, UIMatch, useMatches } from 'react-router'

import { PageLayout } from '@/components/PageLayout'
import { useGlobalState } from '@/globalStore/globalStore'
import { IRouteHandle } from '@/routerConfig/interface'
import { globalPopupSlot } from '../globalPopupSlot'
import { BreadcrumbNav } from './BreadcrumbNav'
import { LeftSider } from './LeftSider'
import styles from './styles.module.less'

export const Biz = () => {
  const matches = useMatches() as UIMatch<unknown, IRouteHandle | undefined>[]
  const isRootRoute = matches.length === 1
  const { roleCodeMap, vip } = useGlobalState(s => s.userInfo!)
  const isAdmin = !!roleCodeMap['100']
  const isEmployee = !!roleCodeMap['101']
  const isCustomer = !!roleCodeMap['102']
  const isFinance = !!roleCodeMap['103']
  const isStore = !!roleCodeMap['104']

  const hideLeftSider = !!matches.find(item => !!item.handle?.hideLeftSider)
  const hideBreadcrumb = !!matches.find(item => !!item.handle?.hideBreadcrumb)
  const noLayout = !!matches.find(item => !!item.handle?.noLayout)

  if (isCustomer && !vip && matches[matches.length - 1].pathname !== '/biz/nonVIP') {
    return <Navigate to="/biz/nonVIP" replace />
  }

  if (isRootRoute) {
    let path = '/biz/order/list' // 商家侧首页
    if (isAdmin) {
      path = '/biz/admin/whiteList' // 超管侧首页
    } else if (isEmployee) {
      path = '/biz/admin/order/audit' // 员工侧首页
    } else if (isFinance) {
      path = '/biz/finance/transaction/recharge' // 财务侧首页
    } else if (isStore) {
      path = '/biz/store/shop/localProductList' // 仓库侧首页
    }
    return <Navigate to={path} replace />
  }

  return (
    <>
      {noLayout ? (
        <Outlet />
      ) : (
        <PageLayout
          minWidth={1280}
          leftSider={!hideLeftSider && <LeftSider />}
          rightContent={
            <div className={styles.box}>
              {!hideBreadcrumb && (
                <div className={styles.breadcrumb}>
                  <BreadcrumbNav />
                </div>
              )}
              <div className={styles.main}>
                <Outlet />
              </div>
            </div>
          }
        />
      )}

      <globalPopupSlot.Slot />
    </>
  )
}
