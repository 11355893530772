import { memo } from 'react'
import { UIMatch, useMatches, useNavigate } from 'react-router'
import { Breadcrumb } from 'antd'
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import { HomeOutlined } from '@ant-design/icons'

import { IRouteHandle } from '@/routerConfig/interface'
import { useActiveMenuNamePath } from './activeMenuNamePath'

/** 面包屑导航 */
export const BreadcrumbNav = memo(function BreadcrumbNav() {
  const navigate = useNavigate()
  const matches = useMatches() as UIMatch<unknown, IRouteHandle | undefined>[]
  const menuNamePath = useActiveMenuNamePath(v => v.namePath)

  let items = matches.map<ItemType>((match, index) => {
    const { id: key, pathname: href, handle } = match
    const title: ItemType['title'] = handle?.name?.(match as UIMatch<unknown, IRouteHandle>) || ''

    const onClick: ItemType['onClick'] = e => {
      e.preventDefault()
      navigate(href)
    }

    const item: ItemType = { key, title, href, onClick }

    // 布局路由
    if (href === '/biz') {
      return { ...item, title: <HomeOutlined /> }
    }
    // 最后一个
    if (index === matches.length - 1) {
      return { key, title }
    }
    // 中间
    return item
  })

  // 插入菜单路径
  if (menuNamePath && menuNamePath.length > 1) {
    const names = menuNamePath.slice(0, -1) // 移除最后一个
    const beforeItems: ItemType[] = names.map<ItemType>((name, i) => ({ key: `${name}@@${i}`, title: name }))
    const startIndex = items[0]?.href === '/biz' ? 1 : 0
    items = [...items]
    items.splice(startIndex, 0, ...beforeItems) // 插入
  }

  return <Breadcrumb items={items} />
})
